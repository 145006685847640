<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">{{$t('PAGES.TERMS.TERMS')}}</span>
      </h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mb-8"
          @click="createTermClicked"
          v-if="!editing"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{$t('PAGES.TERMS.NEW')}}</a
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th>{{$t('COMMON.ACTIVE')}}</th>
              <th>{{$t('COMMON.NAME')}}</th>
              <th>{{$t('COMMON.TYPE')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in terms">
              <ConfirmTermsTableRow
                v-bind:key="i"
                :term="item"
                :currentCompanyId="currentCompanyId"
                @selectTermClicked="selectTermClicked"
                @deleteTermClicked="deleteTermClicked"
                @isActiveChanged="isActiveChanged"
                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <div v-if="editing" class="scroll-to-container">

        <b-card>
          <b-form-group id="input-group-id" label="ID" label-for="input-id">
            <b-form-input
              id="input-id"
              v-model="form.id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>

          <div class="d-flex align-items-center mb-8">
            <label
              class="checkbox checkbox-lg checkbox-outline checkbox-success"
            >
              <input
                type="checkbox"
                name=""
                v-model="form.is_mandatory"
                @click="form.is_mandatory = !form.is_mandatory"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
              >{{$t('COMMON.MANDATORY')}}</span
            >
          </div>


          <div class="d-flex align-items-center mb-8">
            <label
              class="checkbox checkbox-lg checkbox-outline checkbox-success"
            >
              <input
                type="checkbox"
                name=""
                v-model="form.is_active"
                @click="form.is_active = !form.is_active"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
              >{{$t('COMMON.ACTIVE')}}</span
            >
          </div>

          <b-form-group id="input-group-name"
            :label="$t('COMMON.NAME')" label-for="input-name">
            <b-form-input id="input-name" v-model="form.name" type="text"></b-form-input>
          </b-form-group>


          <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">{{$t('PAGES.TERMS.TERMS_TEXT_INFO')}}</div>
          </div>


          <b-form-group id="input-group-descr"
            :label="$t('PAGES.TERMS.CHECKBOX_TEXT')" label-for="input-descr">
            <b-form-input id="input-descr" v-model="form.descr" type="text"></b-form-input>
          </b-form-group>



          <b-form-group id="html-group"
            :label="$t('PAGES.TERMS.TEXT')" label-for="html">
            <tiptap-vuetify
              data-app
              v-model="form.template"
              :extensions="extensions"
            />

          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button type="button" variant="primary" @click="saveTermClicked">{{$t('COMMON.SAVE')}}</b-button>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from 'axios';
import ConfirmTermsTableRow from '@/view/pages/ml/registrationsetup/ConfirmTermsTableRow.vue';
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import { mapGetters } from 'vuex';
import { updateTerms } from '@/core/services/initUserMemlist';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'confirm-terms-table',
  props: ['page','isEvent','category_id'],
  emits: ['showHistoryToggled'],
  mixins: [ toasts ],
  components: {
    ConfirmTermsTableRow,
    TiptapVuetify
  },

  computed: {
    ...mapGetters(['currentCompanyId'])
  },

  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('showHistoryToggled', newcheck);
    }
  },

  mounted() {
    this.loadTerms();
  },

  methods: {

    loadTerms() {
      this.getTerms()
      .then(terms => {
        this.terms = terms.filter(item => item.audience ? item.audience === 'PUBLIC' : true);
      })
      .catch(err => {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_GET'));
      });

    },

    createTermClicked() {
      this.form = {
        name: 'Nytt villkor',
        descr: 'Fyll i text att stå vid checkbox',
        is_mandatory: false,
        company_id: this.currentCompanyId,
        audience: 'PUBLIC'
      }

      this.creating = true;
      this.editing = true;

      this.scrollToEdit();
    },

    isActiveChanged(id, is_active) {
      const term = this.terms.find(item => item.id === id);

      if (!term) {
        console.error('term not found in isActiveChanged');
        return;
      }

      if (term.is_active !== is_active) {
        term.is_active = is_active;
        this.putTerm(id, term)
        .then(res => {
          updateTerms();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.UPDATED'));
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
        });
      }

    },

    saveTermClicked() {
      if (this.creating) {
        this.postTerm(this.form)
        .then(data => {
          this.creating = false;
          this.form = data;
          this.terms.push(data);
          updateTerms();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.CREATED'));
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
        });
      }
      else {
        this.putTerm(this.form.id, this.form)
        .then(res => {

          const index = this.terms.findIndex(item => item.id === this.form.id);

          this.terms[index] = this.form;
          // Vue3 compatability for Vue.set
          this.terms = [...this.terms];
          updateTerms();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.UPDATED'));
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
        });

      }
    },

    selectTermClicked(id) {
      this.editing = true;

      for (const t of this.terms) {
        if (t.id === id) {
          this.form = t;
          this.scrollToEdit();
          return;
        }
      }

    },

    deleteTermClicked(id) {

      this.deleteTerm(id)
      .then(success => {
        this.terms = this.terms.filter(item => item.id !== id);
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.DELETED'));
      })
      .catch(err => {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_DELETE'));
      });

    },

    scrollToEdit() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },



    getTerms() {
      return new Promise(async (resolve, reject) => {
        const url =
          this.isEvent
            ?  `/event_category/terms/${this.category_id}`
            : `/company/allterms/${this.currentCompanyId}`

        axios
          .get(url)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject('error');
            console.error(err);
          });
      });
    },

    getTerm(id) {
      return new Promise(async (resolve, reject) => {
        const url =
          this.isEvent
            ?  `/event_category/term/${id}`
            : `/company/term/${id}`

        axios
          .get(url)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject('error');
            console.error(err);
          });
      });
    },


    postTerm(data) {

      return new Promise(async (resolve, reject) => {
        const url =
            this.isEvent
              ?  `/event_category/term`
              : `/company/term/${this.currentCompanyId}`;

        if (!this.isEvent) {
          data.company_id = this.currentCompanyId;
          data.template = '';
        }
        else {
          data.category_id = this.category_id;
        }

        axios
          .post(url, data)
          .then(res => {

            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status: ' + res.status);
            }
          })
          .catch(err => {
            reject('error');
            console.error(err);
          });
      });


    },

    putTerm(term_id, term) {

      return new Promise(async (resolve, reject) => {

        const url =
            this.isEvent
              ?  `/event_category/term/${term_id}`
              : `/company/term/${term_id}`;

        axios
          .put(url, term)
          .then(res => {

            if (res.status === 204) {
              resolve(true);
            }
            else {
              reject('invalid status ' + res.status);
            }

          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },


    deleteTerm(id) {

      return new Promise(async (resolve, reject) => {
        const url =
          this.isEvent
            ?  `/event_category/term/${id}`
            : `/company/term/${id}`

        axios
          .delete(url)
          .then(res => {
            if (res.status === 204) {
              resolve(true);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject('error');
            console.error(err);
          });
      });

    },

  },

  data() {
    return {
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      creating: false,
      editing: false,

      terms: [], // list of all terms

      form: { // the current term we are editing
        id: '',
        tid: '',
        name: '',
        descr: '',
        template: '',
        is_mandatory: false,
        is_active: false
      }
    };
  }
};
</script>
