<template>
  <div id="page-registration-settings">

    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{ $t('PAGES.REGISTRATION.PAGES') }}</v-tab>
      <v-tab>{{ $t('COMMON.SETTINGS') }}</v-tab>

      <v-tab-item class="pt-8">
        <Confirm
          :title="$t('COMMON.CONFIRM')"
          :message="$t('PAGES.REGISTRATION.CONFIRM_DELETE_PAGE')"
          ref="deletePageConfirm"
          type="success"
          @confirm="onDeletePageConfirm"
        />
        <h5 class="mb-6 ml-2">{{ $t('PAGES.REGISTRATIONSETUP.REGISTER_WIZARD') }}</h5>

        <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()" accept=".css" />

        <b-card class="mb-4">
          <b-row>
            <b-col>
              <b-button type="button" variant="success" @click="selectFile()"
                >{{ $t('PAGES.REGISTRATION.UPLOAD_CSS') }}</b-button
              >

              <div class="register-css-box" v-if="registration.register_css" >
                <b-button type="button" variant="danger" @click="deleteCSSFile()"
                  >{{ $t('PAGES.REGISTRATION.REMOVE_CSS') }}</b-button
                >
                <div class="infobox">
                  <a :href="registration.register_css">{{ registration.register_css }}</a>
                </div>
                
              </div>
            </b-col>
            
          </b-row>
          <b-row>
            <b-col>
              <p class="mt-8">
                {{ link.title }}: 
              </p>
              <p>
                <a :href="link.url">{{ link.url }}</a>
              </p>
            </b-col>
          </b-row>
          

        </b-card>

        <PagesTable
          :items="pages"
          :types="types"
          @moveUpClicked="moveUp"
          @moveDownClicked="moveDown"
          @createPageClicked="createPage"
          @selectPageClicked="selectPage"
          @deletePageClicked="deletePageClicked"
        />
        <div class="scroll-to-container"></div>
        
        <b-card
          title=""
          class="mb-2 edit-reg-container"
          ref="edit-reg"
          id="edit-reg"
          hide-footer
          v-if="creating || editing"
        >
          <div>
            

            <b-form @submit="savePage" class="mt-8">
              <b-form-group id="input-group-id" label="ID" label-for="input-id">
                <b-form-input id="input-id" v-model="page.id" type="text" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-name" :label="$t('COMMON.NAME')" label-for="input-name">
                <b-form-input required id="input-name" v-model="page.name" type="text"></b-form-input>
              </b-form-group>

              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="page.settings.is_renew"
                      @click="page.settings.is_renew = !page.settings.is_renew"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer"
                    >{{ $t('PAGES.REGISTRATION.SHOW_AT_RENEWAL') }}</span
                  >
                </div>
              </b-form-group>

              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="page.settings.is_register"
                      @click="page.settings.is_register = !page.settings.is_register"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer"
                    >{{ $t('PAGES.REGISTRATION.SHOW_AT_REGISTRATION') }}</span
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-type"
                label="Typ"
                label-for="input-type"
                v-if="page.type !== 'confirm'"
              >
                <b-form-select v-model="type" :options="typeOptions"></b-form-select>
              </b-form-group>

              <div v-if="page.type === 'company_select_v2'">

                <CompanySelectRegistrationV2 

                />
              </div>

              <div v-if="page.type === 'personnr'">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.is_mandatory"
                        @click="page.settings.is_mandatory = !page.settings.is_mandatory"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer"
                      >{{ $t('PAGES.REGISTRATION.MUST_FULL_PERSONNR') }}</span>
                  </div>
                </b-form-group>
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.spar"
                        @click="page.settings.spar = !page.settings.spar"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer"
                      >{{ $t('PAGES.REGISTRATION.DO_SPAR') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.check_ladok"
                        @click="page.settings.check_ladok = !page.settings.check_ladok"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer"
                      >{{ $t('PAGES.REGISTRATION.CHECK_LADOK') }}</span>
                  </div>
                </b-form-group>

              </div>

              <div v-if="page.type === 'student'">
                
              </div>


              <div v-if="page.type === 'reg_family'">
                <p class="mt-4 mb-8">{{ $t('PAGES.REGISTRATION.ACTIVATED_FIELDS') }}</p>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        disabled
                        v-model="page.settings.name"
                        @click="page.settings.name = !page.settings.name"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('COMMON.NAME') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.email"
                        @click="page.settings.email = !page.settings.email"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.EMAIL') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.personnr"
                        @click="page.settings.personnr = !page.settings.personnr"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.PERSONNR') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.dob"
                        @click="page.settings.dob = !page.settings.dob"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.DOB') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.phone"
                        @click="page.settings.phone = !page.settings.phone"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.PHONE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.address"
                        @click="page.settings.address = !page.settings.address"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ADDRESS') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.zipcode"
                        @click="page.settings.zipcode = !page.settings.zipcode"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ZIPCODE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.region"
                        @click="page.settings.region = !page.settings.region"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.REGION') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.commune"
                        @click="page.settings.commune = !page.settings.commune"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.COMMUNE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.country"
                        @click="page.settings.country = !page.settings.country"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.COUNTRY') }}</span>
                  </div>
                </b-form-group>

              </div>

              <div v-if="page.type === 'parents'">
                <div class="d-flex align-items-center mb-6 w-50" v-if="page.settings.from_age">
                  <b-input-group class="mb-2 md-8">
                    <b-input-group-prepend is-text
                      >Vårdnadshavare måste vara minst ålder</b-input-group-prepend
                    >
                    <b-form-input
                      type="number"
                      disabled
                      v-model="page.settings.from_age"
                    ></b-form-input>
                  </b-input-group>
                </div>
                <p class="mt-4 mb-8">{{ $t('PAGES.REGISTRATION.ACTIVATED_FIELDS') }}</p>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        disabled
                        v-model="page.settings.name"
                        @click="page.settings.name = !page.settings.name"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('COMMON.NAME') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        disabled
                        v-model="page.settings.email"
                        @click="page.settings.email = !page.settings.email"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.EMAIL') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.personnr"
                        @click="page.settings.personnr = !page.settings.personnr"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.PERSONNR') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.phone"
                        @click="page.settings.phone = !page.settings.phone"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.PHONE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.address"
                        @click="page.settings.address = !page.settings.address"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ADDRESS') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.zipcode"
                        @click="page.settings.zipcode = !page.settings.zipcode"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ZIPCODE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.region"
                        @click="page.settings.region = !page.settings.region"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.REGION') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.commune"
                        @click="page.settings.commune = !page.settings.commune"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.COMMUNE') }}</span>
                  </div>
                </b-form-group>
              </div>

              <div v-if="page.type === 'details'">
                <p style="font-weight: bold;" class="mt-12">{{ $t('PAGES.REGISTRATION.ACTIVATED_FIELDS') }}</p>

                <p style="font-style: italic;">{{ $t('PAGES.REGISTRATION.FIELDS_MANDATORY_INFO') }}</p>


                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.name"
                        @click="page.settings.name = !page.settings.name"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('COMMON.NAME') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.email"
                        @click="page.settings.email = !page.settings.email"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.EMAIL') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.password"
                        @click="page.settings.password = !page.settings.password"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{$t('MEMBER.PASSWORD')}}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.avd"
                        @click="page.settings.avd = !page.settings.avd"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.AVD') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.company_name"
                        @click="page.settings.company_name = !page.settings.company_name"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.COMPANY_NAME') }}</span>
                  </div>
                </b-form-group>

                <b-row>
                  <b-col cols="4">
                    <b-form-group>
                      <div class="d-flex align-items-center">
          
                        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                          <input
                            type="checkbox"
                            name=""
                            v-model="page.settings.birthdate"
                            @click="page.settings.birthdate = !page.settings.birthdate"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{ $t('MEMBER.DOB') }}</span>
                      </div>
                    </b-form-group>

                  </b-col>
                  <b-col cols="4">
                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                          <input
                            type="checkbox"
                            name=""
                            :disabled="!page.settings.birthdate"
                            v-model="page.settings.international_dob"
                            @click="page.settings.international_dob = !page.settings.international_dob"
                          />
                          <span></span>
                        </label>
                        <span class="ml-3 cursor-pointer">{{ $t('MEMBER.INTERNATIONAL_DOB') }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                
                

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.gender"
                        @click="page.settings.gender = !page.settings.gender"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.PUBLIC_SEX') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.phone"
                        @click="page.settings.phone = !page.settings.phone"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.PHONE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.landline"
                        @click="page.settings.landline = !page.settings.landline"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.LANDLINE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.address"
                        @click="page.settings.address = !page.settings.address"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ADDRESS') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.co"
                        @click="page.settings.co = !page.settings.co"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.CO') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.zipcode"
                        @click="page.settings.zipcode = !page.settings.zipcode"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ZIPCODE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.region"
                        @click="page.settings.region = !page.settings.region"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.REGION') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.commune"
                        @click="page.settings.commune = !page.settings.commune"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.COMMUNE') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.country"
                        @click="page.settings.country = !page.settings.country"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.COUNTRY') }}</span>
                  </div>
                </b-form-group>


                <h4>{{ $t('MEMBER.INVOICE_HEADER') }}</h4>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.billing"
                        @click="page.settings.billing = !page.settings.billing"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.INVOICE_ADDRESS') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.invoice_email"
                        @click="page.settings.invoice_email = !page.settings.invoice_email"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.INVOICE_EMAIL') }}</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.invoice_phone"
                        @click="page.settings.invoice_phone = !page.settings.invoice_phone"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">{{ $t('MEMBER.INVOICE_PHONE') }}</span>
                  </div>
                </b-form-group>
              </div>

              
              <div v-if="page.type === 'shop'">
                <!--<p class="mt-4 mb-8">{{ $t('PAGES.REGISTRATION.DISPLAY') }}</p>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.memberships"
                        @click="page.settings.memberships = !page.settings.memberships"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">Visa medlemskap</span>
                  </div>
                </b-form-group>-->

                <!--<b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.extras"
                        @click="page.settings.extras = !page.settings.extras"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">Visa merförsäljning</span>
                  </div>
                </b-form-group>-->
              </div>

              <div v-if="page.type === 'area_alloc'">
                <p class="mt-4 mb-8">Visning</p>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.skip_if_ok"
                        @click="page.settings.skip_if_ok = !page.settings.skip_if_ok"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">Hoppa över ifall automatiskt tilldelad</span>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                        type="checkbox"
                        name=""
                        v-model="page.settings.memberships"
                        @click="page.settings.memberships = !page.settings.memberships"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer">Visa medlemskap</span>
                  </div>
                </b-form-group>
              </div>

              <div v-if="page.type === 'confirm'">

                <b-form-group>
                  <div>
                    <ConfirmTermsTable 
                      :page="this.page"
                      :isEvent="false" />
                  </div>
                </b-form-group>


              </div>

              <b-form-group id="html-group" label="Text" label-for="html">
                <tiptap-vuetify
                  data-app
                  v-model="page.html"
                  :extensions="extensions"
                />
                
              </b-form-group>

              <b-button type="submit" variant="primary">{{ $t('COMMON.SAVE') }}</b-button>
            </b-form>
          </div>
        </b-card>

      </v-tab-item>
      
      <v-tab-item class="pt-8">
        <RegistrationSettingsTab 
        
        />
        
      </v-tab-item>
    </v-tabs>
  </div>


</template>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

input[disabled] + span {
  background-color: #dddddd !important;
}

.infobox {
  border: 1px solid gray;
  padding: 8px;
  float: left;
}


.register-css-box > * {
  float: left;
  margin-right: 8px;
  margin-top: 8px;
}

</style>

<script>

import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';

import axios from 'axios';
import { mapGetters } from 'vuex';
import PagesTable from '@/view/pages/ml/registrationsetup/PagesTable.vue';
import ConfirmTermsTable from '@/view/pages/ml/registrationsetup/ConfirmTermsTable.vue';

import Confirm from '@/view/components/Confirm.vue';
import CompanySelectRegistrationV2 from './registrationsetup/company_select_pages/CompanySelectRegistrationV2.vue';
import RegistrationSettingsTab from './registrationsetup/RegistrationSettingsTab.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'registrationsetup',
  mixins: [ toasts ],
  components: {
    TiptapVuetify,
    PagesTable,
    ConfirmTermsTable,
    Confirm,
    CompanySelectRegistrationV2,
    RightSaveButton,
    RegistrationSettingsTab
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
    link() {
      return { title: this.$t('PAGES.SETTINGS.MEMBER_REG_PAGE'), url: get_domain_url() + '/register/' + this.currentCompanyId }
    }
  },
  mounted() {
    this.loadPages();
  },
  data() {
    return {
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      page: {
        name: 'test',
        active: false,
        html: 'content',
        index: 0,
        type: 'personnr',
        id: 'aaa',
        settings: {}
      },
      settings: {
        personnr: {
          is_mandatory: false,
          spar: false
        },
        details: {
          address: false,
          birthdate: false,
          phone: false,
          company_name: false
        },
        shop: {
          memberships: false,
          extras: false
        },
        parents: {
          address: false,
          personnr: false,
          phone: false,
          from_age: 18
        }
      },
      selectedIds: [],
      typeOptions: [
        { value: 'personnr', text: this.$t('PAGES.REGISTRATIONSETUP.PERSONNR') },
        { value: 'student', text: this.$t('PAGES.REGISTRATIONSETUP.STUDENT') },
        { value: 'details', text: this.$t('PAGES.REGISTRATIONSETUP.DETAILS') },
        { value: 'reg_family', text: this.$t('PAGES.REGISTRATIONSETUP.REG_FAMILY') },
        { value: 'properties', text: this.$t('PAGES.REGISTRATIONSETUP.PROPERTIES') },
        { value: 'parents', text: this.$t('PAGES.REGISTRATIONSETUP.PARENTS') },
        { value: 'shop', text: this.$t('PAGES.REGISTRATIONSETUP.SHOP') },
        { value: 'company_select_v2', text: this.$t('PAGES.REGISTRATIONSETUP.COMPANY_SELECT_V2') },
        { value: 'confirm', text: this.$t('PAGES.REGISTRATIONSETUP.CONFIRM') }
      ],
      types: {
        personnr: this.$t('PAGES.REGISTRATIONSETUP.PERSONNR'),
        student: this.$t('PAGES.REGISTRATIONSETUP.STUDENT'),
        details: this.$t('PAGES.REGISTRATIONSETUP.DETAILS'),
        properties: this.$t('PAGES.REGISTRATIONSETUP.PROPERTIES'),
        parents: this.$t('PAGES.REGISTRATIONSETUP.PARENTS'),
        shop: this.$t('PAGES.REGISTRATIONSETUP.SHOP'),
        company_select_v2: this.$t('PAGES.REGISTRATIONSETUP.COMPANY_SELECT_V2'),
        area_alloc: this.$t('PAGES.REGISTRATIONSETUP.AREA_ALLOC'),
        confirm: this.$t('PAGES.REGISTRATIONSETUP.CONFIRM'),
        reg_family: this.$t('PAGES.REGISTRATIONSETUP.REG_FAMILY')
      },
      creating: false,
      editing: false,
      pages: [
        {
          name: 'test',
          active: false,
          html: 'content',
          index: 0,
          type: 'personnr',
          id: 'aaa',
          settings: {}
        }
      ],
      toDelete: null,
      type: '',
      ref_page_confirmId: '',
      age_req_parent: [null, null],
      registration: {}
    };
  },

  watch: {
    type(newValue, oldValue) {
      this.page.type = newValue;

      switch (newValue) {
        case 'details':
          this.page.settings.name = true;
          this.page.settings.email = true;
          break;
        case 'parents':
          this.page.settings.name = true;
          this.page.settings.email = true;
          this.page.settings.from_age = 18;
          break;
        case 'reg_family':
          this.page.settings.name = true;
          this.page.settings.email = true;
          break;
        case 'confirm':
          this.page.settings.accept_terms = true;
          break;
        default:
          break;
      }
    },
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadPages();
      }
    },
    page() {
      if (this.page.settings.selected_company_ids) {
        this.selectedIds = this.page.settings.selected_company_ids;
      }
    }
  },

  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    deleteCSSFile() {
      axios 
        .put(`/company/settings/registration/${this.currentCompanyId}`, { 
          register_css: null
        })
        .then(res => {
          if (res.status === 204) {
            this.registration.register_css = null;
            this.toastr('success', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.CSS_DELETED') );
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_DELETE_CSS') );
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_DELETE_CSS') );
        });
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_type', 'REGISTER_CSS');
      formData.append('company_id', this.currentCompanyId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FILES.UPLOADED'));
            this.registration.register_css = res.data.files[0];
            this.$refs.file.value = null;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_UPLOAD'));
          }

        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_UPLOAD'));
          console.error(err);
        });
    },
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.loadPages();
    },

    moveUp(page_id) {

     
      const index = this.pages.findIndex(item => item.id === page_id);

      if (index <= 0) {
        return;
      }

      const page = this.pages[index];

      this.pages.splice(index, 1);

      this.pages.splice(index-1, 0, page);

      this.sortPages();
    },

    moveDown(page_id) {
      const index = this.pages.findIndex(item => item.id === page_id);
      
      if (index >= this.pages.length-1) {
        return;
      }

      const page = this.pages[index];

      this.pages.splice(index, 1);

      this.pages.splice(index+1, 0, page);

      this.sortPages();
    },

    sortPages() {
      const indexes = this.pages.map(item => (item.id));

      axios 
        .put(`/company/pageorder`, { page_ids: indexes })
        .then(res => {
          if (res.status !== 204) {
            this.toastr('danger', this.$t('COMMON.ERROR', this.$t('PAGES.REGISTRATIONSETUP.UNABLE_SORT')))
          }
          else {
            this.loadPages();
          }
        })
        .catch(err => {
          console.error(err);

          this.toastr('danger', this.$t('COMMON.ERROR', this.$t('PAGES.REGISTRATIONSETUP.UNABLE_SORT')));
        });
    },

    movePage(page_id, dir) {
      axios
        .put('/company/regpagemove', { id: page_id, dir: dir, company_id: this.currentCompanyId })
        .then(res => {
          this.loadPages();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_CREATE_PAGE'));
        });
    },

    createPage() {
      
      this.creating = true;
      this.editing = true;

      this.page = { index: this.pages.length, settings: { is_renew: true, is_register: true } };
      this.type = null;

      this.scrollToEditPage();
    },

    savePage(e) {
      e.preventDefault();

      if (this.creating) {
        this.page.company_id = this.currentCompanyId;

        this.postPage()
          .then(data => {
            this.creating = false;
            this.page = data;
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.REGISTRATIONSETUP.PAGE_CREATED'));

            if (this.page.type != 'confirm') {
              this.editing = false;
              this.loadPages();
            }
          })
          .catch(err => {
            console.error('postPage error', err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_CREATE_PAGE'));
          });

      } else {
        this.putPage()
          .then(data => {
            this.creating = false;
            
            if (this.page.type != 'confirm') {
              this.editing = false;
              this.page = data;
              this.loadPages();
            }

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.REGISTRATIONSETUP.PAGE_UPDATED'));
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_PAGE_UPDATE'));
          });
      }
    },

    putPage() {

      this.page.company_id = this.currentCompanyId;

      return new Promise(async (resolve, reject) => {
        axios
          .put('/company/regpage', this.page)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('server error');
          });
      });

      
    },

    postPage() {

      return new Promise(async (resolve, reject) => {
        axios
          .post('/company/regpage', this.page)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },

    deletePageClicked(id) {
      this.toDelete = id;
      this.$refs['deletePageConfirm'].show();
    },
    onDeletePageConfirm() {
      this.deletePage(this.toDelete);
      this.toDelete = null;
    },
    deletePage(id) {
      axios
        .delete(`/company/regpage?id=${id}&company_id=${this.currentCompanyId}`)
        .then(res => {
          this.pages = this.pages.filter(item => item.id !== id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.REGISTRATIONSETUP.PAGE_DELETED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_DELETE_PAGE'));
        });
    },

    selectPage(id) {
      for (var i = 0; i < this.pages.length; ++i) {
        if (this.pages[i].id === id) {
          this.page = this.pages[i];

          if (this.page.settings === undefined || this.page.settings === null) {
            this.page.settings = {};
          }

          this.creating = false;
          this.editing = true;
          this.type = this.page.type;

          this.scrollToEditPage();
          break;
        }
      }
    },
    selectedCompanies(newValue) {
      this.page.settings.selected_company_ids = newValue.map(i => i.code);
    },
    createConfirmPage() {
      this.page.company_id = this.currentCompanyId;
      
      var pageData = {
        index: 0,
        type: 'confirm',
        company_id: this.currentCompanyId,
        name: 'Bekräftelse',
        settings: { terms: [] }
      };
      axios
        .post('/company/regpage', pageData)
        .then(res => {
          if (res.status === 201) {
            this.creating = false;
            this.editing = false;
            this.loadPages();
            this.ref_page_confirmId = res.data.id;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_CREATE_PAGE'));
        });
    },

    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },

    loadPages() {
      this.pages = [];
      
      axios
        .get(`/company/admin?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.pages = res.data.pages;
            this.age_req_parent = res.data.registration.age_req_parent;
            this.registration = res.data.registration;


            if (this.pages == undefined) {
              this.pages = [];
            }

            this.pages = this.pages.filter(item => item.type !== 'onepager');

            let tempConfirmPages = this.pages.filter(function(item) {
              return item.type == 'confirm';
            });

            this.pages = this.pages.sort(function(a, b) {
              if (a.index < b.index) {
                return -1;
              }
              return 1;
            });

          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATIONSETUP.UNABLE_LIST_PAGES'));
        });
    },

    scrollToEditPage() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>
